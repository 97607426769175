import sendSentryError from "./sendSentryError";

export default async (survey, isCompleted, generateDocuments) => {
  let data = survey.data;
  data.pageNo = survey.currentPageNo;
  try {
    await sendDataToServer(data, isCompleted, generateDocuments);
  } catch (error) {
    console.error(error);
  }
};

async function sendDataToServer(json, isCompleted, generateDocuments) {
  return new Promise((resolve, reject) => {
    const formData = createFormData(json, isCompleted, generateDocuments);
    const url = document.getElementById("surveyContainer").getAttribute("data-response-url");
    const request = new XMLHttpRequest();

    request.onreadystatechange = function () {
      if (request.readyState === 4) {
        if (request.status === 200 || request.status === 204) {
          resolve();
        } else if (isCompleted) {
          sendSentryError(
            `Server failed to save request. HTTP Code: ${request.status}. Status Text: ${request.statusText}. URL: ${url}`
          );
          showAlert();
          reject(new Error("Failed to save survey data."));
        }
      }
    };

    try {
      sendRequest(request, url, formData);
    } catch (err) {
      sendSentryError(`Server can't be contacted. Error: ${err}. URL: ${url}`);
      showAlert();
      reject(err);
    }
  });
}

function createFormData(json, isCompleted, generateDocuments) {
  const formData = new FormData();
  formData.append("payload", JSON.stringify(json));
  formData.append("isCompleted", isCompleted);
  formData.append("generateDocuments", generateDocuments);
  return formData;
}

function sendRequest(request, url, formData) {
  request.open("POST", url);
  request.setRequestHeader(
    "X-CSRF-Token",
    document.querySelector("meta[name=csrf-token]")?.content
  );
  request.send(formData);
}

function showAlert() {
  alert(
    "Something went wrong while saving your data. Please try again later, or ask your arranger to save your data."
  );
  window.location.reload();
}
